import React from "react";
import styled from "styled-components";
import { ImSpinner8 } from "react-icons/im";

const LoadingIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  z-index: 10;
  transition: all ${(props) => props.theme.transitions.med};

  &.-white {
    > svg {
      color: ${(props) => props.theme.colours.white} !important;
    }
  }

  > svg {
    font-size: 3rem;
    color: ${(props) => props.theme.colours.Primary} !important;
    animation: load 1s infinite;

    @keyframes load {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const Loader = ({ show, white }) => (
  <>
    {show && (
      <LoadingIcon className={white ? "-white" : ""}>
        <ImSpinner8 />
      </LoadingIcon>
    )}
  </>
);
