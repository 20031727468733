import React from "react";
import styled from "styled-components";

import Success_Icon from "../../images/graphics/icons/Icon_AnimatedTick.svg";

import { Box } from "../util";

const SuccessResponse = styled(Box)`
  display: flex;
  background: ${(props) => props.theme.colours.white};
  border-radius: ${(props) => props.theme.styles.borderRadius};
  padding: 40px;
  /* box-shadow: ${(props) => props.theme.styles.shadow}; */
  align-items: center;
  flex-direction: column;
  text-align: center !important;
  max-width: 500px;
  z-index: 96;

  &.-greyBackground {
    background: ${(props) => props.theme.colours.midGrey};
  }

  &.-noShadow {
    box-shadow: none !important;
    filter: none !important;
  }

  img {
    max-width: 120px;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      max-width: 70px !important;
    }
  }

  h2,
  p,
  b {
    padding-left: 0 !important;
    color: ${(props) => props.theme.colours.dark} !important;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 20px;
  }
`;

export const Success = ({
  show,
  customIcon,
  title,
  message,
  customClass,
  centerAlign,
  noShadow,
}) => (
  <>
    {show && (
      <SuccessResponse
        gap={20}
        className={`${customClass} ${centerAlign ? "-center" : ""} ${
          noShadow ? "" : "-addShadow"
        }`}
      >
        <img
          src={`${customIcon ? customIcon : Success_Icon}`}
          alt="Form Successfully Submitted"
        />
        <div>
          <h2>{title}</h2>
          <p>{message}</p>
        </div>
      </SuccessResponse>
    )}
  </>
);
