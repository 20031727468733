import React, { useState } from "react";
import { Success, Loader } from "../components/feedback";
import { Container, Wrapper } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Contact = (props) => {
  let [success, setForm] = useState(false);
  let [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    setTimeout(function() {
      setForm(true);
      setLoading(false);
    }, 2000);
  };

  return (
    <Layout>
      <Seo title="Contact" pathname={props.location.pathname} />
      <Container>
        <Wrapper maxWidth={600}>
          <h1 className="-textCenter">Contact Us</h1>
          <Loader show={loading} />
          {!success ? (
            <>
              <iframe name="stopRedirect" css={{ display: "none!important" }} />
              <form
                name="Contact"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                target="stopRedirect"
                onSubmit={handleSubmit}
                css={{
                  opacity: loading ? "0.5" : "1",
                  pointerEvents: loading ? "none" : "all",
                }}
              >
                <input css={{ display: "none!important" }} name="bot-field" />
                <p>
                  <label>
                    Name <input type="text" name="name" />
                  </label>
                </p>
                <p>
                  <label>
                    Email <input type="email" name="email" />
                  </label>
                </p>
                <p>
                  <label>
                    Contact Number <input type="phone" name="phone" />
                  </label>
                </p>
                <p>
                  <label>
                    Message <textarea name="message" />
                  </label>
                </p>
                <p>
                  <button className="primary" type="submit">
                    Send Message
                  </button>
                </p>
              </form>
            </>
          ) : (
            <Success
              show={true}
              title="Thanks for the message!"
              message="I'll get back to you soon."
              centerAlign
            />
          )}
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Contact;
