import React from "react";
import styled from "styled-components";
import { IoIosWarning } from "react-icons/io";

const EmptyStateContainer = styled.div`
  background-color: ${(props) => props.theme.colours.lightGrey};
  border: dashed 1px ${(props) => props.theme.colours.greyDarken};
  border-radius: ${(props) => props.theme.styles.borderRadius};
  padding: 60px 40px;
  color: #777;

  > svg {
    font-size: 50px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.colours.greyDarken};
  }

  > h4 {
    max-width: 550px;
  }
`;

export const EmptyState = ({ show, message }) => (
  <>
    {show && (
      <EmptyStateContainer className="-textCenter">
        <IoIosWarning />
        <h4 className="-center">{message}</h4>
      </EmptyStateContainer>
    )}
  </>
);
