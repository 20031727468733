import React from "react";
import styled from "styled-components";
import { IoIosWarning } from "react-icons/io";

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colours.red};
  color: ${(props) => props.theme.colours.white};
  padding: 20px;
  max-height: 1000px;
  border-radius: ${(props) => props.theme.styles.borderRadius};
  margin-bottom: 10px;
  text-align: left;
  opacity: 1;
  transition: all ${(props) => props.theme.transitions.med};

  &.-hide {
    /* max-height: 0;
    padding: 0;
    margin-bottom: 0;
    opacity: 0;
    margin-top: 0; */
    display: none;
  }

  /* Can do this better.. also on checkbox standard */
  svg {
    font-size: 22px;
    margin-right: 10px;
    flex: 0 0 auto;
  }

  span {
    flex: 0 1 auto;
  }
`;

export const Error = ({ message }) => (
  <>
    {message && (
      <ErrorContainer>
        <IoIosWarning />
        <span>{message}</span>
      </ErrorContainer>
    )}
  </>
);
